<template>
  <component :is="commonComponent" id="blog" classs="blog">
    <div class="blog-container pc_padding m_set_width">
      <h1 class="m-b-30 head-1">
        Seven Powerful Ways of Thinking in the Bible
      </h1>

      <div class="autor m-b-30">
        <div>Author: Irene </div>
        <div>Publish: 2025/1/3 </div>
      </div>

      <AdComponent
        v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.blog"
        ref="ads-blog-1" class="ads-insert-max m-t-40" :ads="adsensConfig?.blog"
        :showDebug="showDebug" />

      <p>
        If you don&#39;t read the Bible (66 books), you won&#39;t know the vastness of the world
        and the wisdom of life.
      </p>

      <p>
        Throughout history, countless great men have read this holy book, either as a secret book
        for self-cultivation or as a model of wisdom.
      </p>

      <p>
        This treasure of world literature embodies the wisdom of life. The creation of this book
        began in 1500 BC and lasted until about 97 AD. It was completed by more than 40 authors from
        different eras in Hebrew, Aramaic, Greek and other languages in many regions of Asia, Africa
        and Europe. German writer Goethe said that all my profound thoughts come from my belief in
        the Bible; my moral life and literary works are all guided by the Bible. The Bible is the
        most reliable and powerful capital in my life, and it is really an inexhaustible treasure
        house. Understanding these 7 kinds of thinking in the Bible can help you embark on a path of
        understanding and have lively hope in life.
      </p>

      <h2>Don&#39;t worry</h2>

      <p>
        The book repeatedly warns people not to worry, because it is the most useless thinking.
        Once the heart is occupied by worry, it is impossible to move forward towards the goal.
      </p>

      <p>
        There are many things in life that are not satisfactory. If you worry when you encounter
        something unpleasant, then most of your life will be occupied by worry, which is definitely
        not a normal life trajectory for a person. There are only two levels of worry. One is to
        have an unbearable mentality towards things that have already happened, so as to passively
        accept them and then produce sorrow; the other level is to worry too much about things that
        have not happened in the future, fearing that the results will affect you. The book says
        that you should put down all your worries and hand them to the saint, just like removing a
        huge rock from yourself.
      </p>

      <p>
        <img alt="img"
          src="@/assets/ZjJiMDk0MzY1OGY0N2Y3MmY3OTVkODcxMjdiZDRiNjlfOVN5ZVBPZHBpQncwWGRFU2lMbk9rTlk1aVAxdWx2YXFfVG9rZW46VW02TGIyeTFEb2hQTkN4NFlTb2M1SzlWbmljXzE3MzMzNzk2NDc6MTczMzM4MzI0N19WNA.png">
      </p>

      <h2>Do not be deceitful</h2>

      <p>
        The book mentions that people must be careful when speaking, and do not say any evil or
        deceitful words.
      </p>

      <p>
        In addition to speaking, it also mentions that people who have no deceit in their hearts
        are blessed. In real life, people like to associate with honest people, and no one wants to
        get along with people who are deceitful. The saints despise all fraudulent means. No matter
        what the purpose is, as long as you do something deceitful, you will not get any benefit,
        but will suffer losses.
      </p>

      <p>
        The first loss is that the benefits obtained by doing deceitful things may be lost; the
        second loss is that others will no longer believe in you from now on.
      </p>

      <p>
        It can be seen from this that not being deceitful is the choice of smart people, and only
        foolish people will choose this most unprofitable way.
      </p>

      <h2>Treat others as you would like to be treated</h2>

      <p>
        The book says that you should treat others the way you want others to treat you. Most
        people think that you should treat others the way you treat me, as if it is the other
        person&#39;s business. But the book says that it depends on you. The way you treat others is
        based on how you want others to treat you, not on how they treat you.
      </p>

      <p>
        Many philosophical thoughts are about not doing something, such as not doing to others what
        you do not want others to do to you. Such teachings are usually negative. The words in the
        Bible are positive. It is not about restraining yourself from harming others, but about
        benefiting others; not only should you not rob others, but you should also give to others;
        not only should you not kill people, but you should also love others.
      </p>

      <h2>Guard your heart</h2>

      <p>The book says that guarding your heart is better than guarding anything else.</p>

      <p>
        Nowadays, people&#39;s living conditions are getting better and better, and they have more
        and more assets. People always take good care of their cherished items. You will see that
        men usually love cars and treat them very carefully. They not only take good care of them,
        but also check them regularly.
      </p>

      <p>
        Women pay much attention to their appearance and take care of their skin by buying a lot of
        beauty products. However, there is no blame for the above behavior, but it just means that
        many times people forget their hearts, which are the most important things to protect and
        preserve. Because the achievements and deeds of a person&#39;s life are all from the heart.
      </p>

      <p>
        <img alt="img"
          src="@/assets/MWU3Zjk2Mjk4MjQ3NjEwZjlhYzNiYTFlZGIyM2IwNTJfTDl6czUwM2s4ZE82Vm85ZTk1YzlPa2Mzako0MmNGTU1fVG9rZW46UmkxRGJ5STNPb2VWZGZ4SWI3U2NnSlZpbnhjXzE3MzMzNzk2NDc6MTczMzM4MzI0N19WNA.png">
      </p>

      <h2>Awe in the heart</h2>

      <p>
        The book mentions that we came into the world empty-handed and will leave the world
        empty-handed. The saint gives and takes.
      </p>

      <p>So, never expect to have more, but you must know how to cherish what you have.</p>

      <p>
        People who are awe-inspiring will not be proud at every turn, but will maintain a humble
        heart. The more arrogant a person is, the more likely he is to have problems. A humble
        person will always reflect on whether he has made mistakes. No one is without faults. What
        matters is that he can repent in time. People who reform themselves often have more
        opportunities.
      </p>

      <p>
        Awe in the heart is also a kind of pattern. Such people have a broad mind and are not
        self-centered.
      </p>

      <h2>Recognize your noble identity</h2>

      <p>
        The book mentions that we must be holy and stay away from filth. This is because our
        identity is very noble.
      </p>

      <p>
        A truly noble soul must reside in a holy and noble body. This is not for anyone, but for
        one&#39;s own noble identity.
      </p>

      <p>
        Nowadays, many people apply for VIP membership cards. This identity seems to be much more
        noble than ordinary people because they can enjoy more and better services.
      </p>

      <p>
        If you want to improve your personal cultivation and quality, you can&#39;t achieve it by
        applying for a membership card. You must recognize that you are already noble, and
        constantly renew your heart, so that your life will continue to build.
      </p>

      <h2>Love without cessation</h2>

      <p>
        The book mentions that we should love ourselves and others. What is love? Love is constant
        patience and a kind heart. It is not boastful or arrogant. It does not do shameful things.
      </p>

      <p>
        Love is forgiveness and is not easily angered. True love is often reflected in the smallest
        details of life. Many times we can love each other to death for him, but we can&#39;t
        squeeze toothpaste for him every day, and we may even argue over who cooks and who washes
        the dishes.
      </p>

      <p>
        Especially when we are hurt, we feel that we can no longer love. But the book says that we
        must love no matter what.
      </p>

      <p>
        Because the saint loved us when we were unlovable. Love is a necessity for human beings,
        just like oxygen and water. French free thinker Rousseau said that the solemnity of the
        Bible is admirable. The purity of the gospel makes me feel heartbroken. After reading the
        Bible, all philosophical works become humble and incomparable.
      </p>
    </div>

    <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.blog"
      ref="ads-blog-1" class="pc_padding m-t-40" :ads="adsensConfig?.blog" :showDebug="showDebug" />

    <div class="blog-list-container ">
      <div class="blog-list-title">
        Recommend Reading
      </div>
      <div class="blog-card-row">
        <div v-for="(data, i) in card" :key="i" class="blog-card-item cursor"
          @click="goto(data.routename)">
          <img :src="data.img" class="blog-card-image" :alt="data.title">
          <div class="blog-card-content">
            <div class="blog-card-title">
              {{ data.title }}
            </div>
            <div class="blog-card-desc">
              {{ data.desc }}
            </div>
          </div>
          <div class="blog-card-btn">
            Read More <img src="@/assets/readmore.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <a href="/blogs">
      <div class="blog-all-btn cursor">
        View All
      </div>
    </a>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/blog_base.scss';

export default {
  name: 'Blog1',
  components: {
    AdComponent,
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
      card: [
        {
          img: require('@/assets/blog-2.png'),
          title: 'Greed in the Bible',
          desc: "The concept of coveting in the Bible is highly significant for understanding its morality and ethics. Essentially, coveting is having a strong longing for what belongs to others. This simple yet profound concept, deeply rooted in Biblical teachings like the Ten Commandments, reveals much about human nature and spiritual expectations.In this article, we will take a deeper look at greed as described in the Bible.",
          routename: 'greed-in-the-bible',
        },
        {
          img: require('@/assets/blog-3.png'),
          title: 'What does the Bible say about judging others',
          desc: "In our daily lives, we often find ourselves in the position of passing judgment on others. Whether it's a quick assessment of someone's behavior, appearance, or choices, we seem to have an innate tendency to form opinions and judgments. But what does the Bible have to say about this all-too-common human trait?",
          routename: 'what-does-the-bible-say-about-judging-others',
        },
        {
          img: require('@/assets/blog-4.png'),
          title: 'Revelation from the Stories of People Who Overcame Suffering in the Bible',
          desc: "In the Bible, there are many people who have experienced suffering. They showed admirable tenacity and courage in difficult situations. With their faith and strength, they turned suffering into grace, making life and living more abundant and meaningful. Next, let us learn how they faced suffering, got out of difficulties, and made life better.",
          routename: 'revelation-from-the-stories-of-people-who-overcame-suffering-in-the-bible',
        },

      ],
    };
  },
  computed: {
    title () {
      return `Seven Powerful Ways of Thinking in the Bible | ${this.domainConfig?.['meta']?.title || 'Default Title'}`
    },
    description () {
      return `German writer Goethe said that all my profound thoughts come from my belief in the Bible; my moral life and literary works are all guided by the Bible. The Bible is the most reliable and powerful capital in my life, and it is really an inexhaustible treasure house. Understanding these 7 kinds of thinking in the Bible can help you embark on a path of understanding and have lively hope in life.`
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.loadAdSenseScript();  // 重新加载广告脚本
    if (typeof window !== 'undefined') {
      this.initAdTracking(); //客户端交互
    }
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    if (this.checkInterval) {
      clearInterval(this.checkInterval); //客户端交互移除
    }
    if (this.visibilityChangeHandler) {
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);//客户端交互移除
    }
  },
  methods: {
    goto (routername) {
      window.location.href = `/${routername}`
    },
    // 防抖函数 + 滚动
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      const documentHeight = document.documentElement.scrollHeight;// 获取文档的总高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // 当前滚动的高度
      const windowHeight = window.innerHeight;// 视口的高度
      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        // this.getImages();
      }
    },
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);
      if (!adsElements.length) {
        console.log('No ad elements to observe');
        return; // 如果没有广告元素，直接返回
      }

      if (!this.observedAds) {
        this.observedAds = new Set();
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;
        if (adElement && adElement instanceof HTMLElement && !this.observedAds.has(adElement)) {
          observer.observe(adElement);
          this.observedAds.add(adElement);
          console.log('Observing new ad element:', adElement);
          console.log(this.observedAds);
        }
      });

    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互

  }
};
</script>
